import * as SECTIONS from "./sections";

const sections = [
  SECTIONS.INTRODUCTION,
  SECTIONS.FLOW_INTEGRATIONS,
  // SECTIONS.TESTING_INFORMATION,
  // SECTIONS.CREATE_SHIPMENT_GUIDANCE,
  // SECTIONS.INTERNATIONAL_SHIPMENT_INFORMATION,
  // SECTIONS.LABEL_OUTPUT,
  // SECTIONS.BARCODE_METRICS,
  // SECTIONS.ERROR_HANDLING,
];

export { sections as SECTIONS };
