import React from "react";

import { PAGES, PATHS } from "../../index";

import { Link } from "../../../components/Actionable/";
import { RoundedBorder } from "../../../components/Svgs";
import BodyParagraph from "../../../components/Typography/BodyParagraph";
import MessageCard from "../../../components/Typography/MessageCard";
import { sanitisePath } from "../../../helpers/strings";
import getAvailableEndpoints from "../helpers/page";

export const INTRODUCTION = {
  summary: "Introduction",
  body: (
    <>
      <BodyParagraph>
        The initial step in integrating with us is to utilize our authentication
        APIs. These API’s allow you to access our services within your
        implementation.
      </BodyParagraph>
      <BodyParagraph>
        Explore the technical documentation for our available endpoints below:
      </BodyParagraph>
      <BodyParagraph>{getAvailableEndpoints(PAGES.AUTH)}</BodyParagraph>
    </>
  ),
};
export const FLOW_INTEGRATIONS = {
  summary: "Integration",
  body: (
    <>
      <MessageCard
        messageType={"note"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            An active DPD/DPD Local account and an API key and secret pair are
            required when integrating with these API’s . Please see{" "}
            <Link to={PATHS.REGISTRATION}>
              Getting Started Registration page
            </Link>{" "}
            for more information.
          </div>
        }
      />
      <BodyParagraph>Example Flow:</BodyParagraph>
      <div className="container mx-auto px-4 sm:px-6 md:px-8">
        <RoundedBorder>
          <img src={"/images/svg/AuthFlow.svg"} alt={"Authentication Flow"} />
        </RoundedBorder>
      </div>
    </>
  ),
};

export const TOKEN_INFORMATION = {
  summary: "JWT Token Information",
  body: (
    <>
      <BodyParagraph>
        JWT token (JSON Web Token) is built of 3 parts, header.payload.signature
      </BodyParagraph>
      <BodyParagraph>
        <ul>
          <li>
            <span className="font-bold">Header</span> - Holds header information
            about the token such as the algorithm used to sign this token and
            the type for the token.
          </li>
          <li>
            <span className="font-bold">Payload</span> - Holds custom claims,
            this is a JSON object where some custom data related to the token is
            stored.
          </li>
          <li>
            <span className="font-bold">Signature</span> - This is a signature
            which was generated using the header and the payload along with a
            secret key.
          </li>
        </ul>
      </BodyParagraph>
      <BodyParagraph>
        When a JWT token is validated the signature is used to check if the
        header and the payload are valid. If at any point the header or the data
        in the payload changes the signature becomes invalid.
        <br />
        Both the access and refresh tokens contain custom data. Below is a list
        of these fields available in the payload body:
      </BodyParagraph>
      <BodyParagraph>
        <ul>
          <li>
            <span className="font-bold">user_id</span>: Unique per
            customer/user. Formatted using businessUnit (1= DPD or 2 = DPD
            Local), customer account number and username in format
            businessUnit_accountNumber_username dpd_account: Customers account
            number
          </li>
          <li>
            <span className="font-bold">dpd_account</span>: Customers account
            number
          </li>
          <li>
            <span className="font-bold">dpd_business_id</span>: Customers
            Business Unit (DPD (1) or DPD Local (2))
          </li>
          <li>
            <span className="font-bold">expiry</span>: Token expiry time. This
            is a numeric value representing the number of seconds from the date
            01/01/1970 at 00:00:00 UTC time.
            <MessageCard
              messageType={"example"}
              message={
                <div style={{ wordWrap: "break-word", color: "red" }}>
                  1698923072 relates to 02/11/23 11:04:32
                </div>
              }
            />
          </li>
        </ul>
      </BodyParagraph>
      <BodyParagraph>
        Other data is presented in the JWT token however can be ignored and is
        intended for internal use only
      </BodyParagraph>
      <BodyParagraph>
        dpd_suspended
        <br />
        dpd_account_override
        <br />
        dpd_expiry
        <br />
        shipping_version
        <br />
        shipping_version
        <br />
        product_access
        <br />
      </BodyParagraph>
    </>
  ),
};

export const TOKEN_EXPIRY = {
  summary: "JWT Token Expiry",
  body: (
    <>
      <BodyParagraph>
        Within the token response received via the Access Token API a field
        exists within the payload body named “expiry”.
        <br />
        This is a numeric value representing the number of seconds from the date
        01/01/1970 at 00:00:00 UTC time.
        <br />
        This numeric value (Epoch Unix Timestamp) when converted into date and
        time gives the information of when the token is due to expire.
      </BodyParagraph>
    </>
  ),
  messageCards: [
    {
      type: "example",
      message: (
        <>
          1698923072 relates to 02/11/23 11:04:32
          <br />
          This normally returns a value of 24 hours in the future from the point
          of token creation.
        </>
      ),
    },
    {
      type: "note",
      message: "Expiry time could be subject to change.",
    },
  ],
};

export const ACCESS_TOKEN = {
  summary: "Access Token",
  body: (
    <>
      <BodyParagraph>Access Token</BodyParagraph>
      <BodyParagraph>
        You will need an access token to be able to access / use our APIs. The
        Access Token API can be used to create an access token using your API
        key and secret.
      </BodyParagraph>
      <BodyParagraph>
        The access token returned is a JWT Token (JSON Web Token), Please see{" "}
        <Link to={`${sanitisePath(TOKEN_INFORMATION.summary)}`}>
          {TOKEN_INFORMATION.summary}
        </Link>{" "}
        for further details on the technical details of the token returned.
      </BodyParagraph>
      <BodyParagraph>
        An access token lasts for 24 hours and must be refreshed before it
        expires, otherwise a new one will need to be requested. Please see{" "}
        <Link to={`${sanitisePath(TOKEN_EXPIRY.summary)}`}>
          {TOKEN_EXPIRY.summary}
        </Link>{" "}
        for information on how to determine token expiry provided within.
      </BodyParagraph>
      <BodyParagraph>
        Authentication is provided within the API’s by way of an “API Key” and
        must be authenticated using Basic authentication, The API key is a
        unique identifier that is allocated and provided to a customer and acts
        as authentication to be able to use the API’s and used to identify the
        customer / user.
      </BodyParagraph>
      <BodyParagraph>
        This API requires the "key" and "secret" to be encoded using base64 and
        then passed as an Authorization header in the HTTPS request.
      </BodyParagraph>
      <MessageCard
        messageType={"example"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            Key = key1234567890123456789
            <br />
            Secret = secret9876543210987654321
            <br />
            <br />
            Concatenate the key and secret with a colon in between and encode as
            base64 as a single string: e.g:
            “key1234567890123456789:secret9876543210987654321”
          </div>
        }
      />
      <MessageCard
        messageType={"example"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            -Authorization:- Basic
            a2V5MTIzNDU2Nzg5MDEyMzQ1Njc4OTpzZWNyZXQ5ODc2NTQzMjEwOTg3NjU0MzIx
          </div>
        }
      />
      <BodyParagraph>
        If successful this will return a HTTP 200 Response with accessToken &
        refreshToken. These tokens should be used to provide authentication for
        any following requests.
      </BodyParagraph>
    </>
  ),
};
export const REFRESH_ACCESS_TOKEN = {
  summary: "Refresh Access Token",
  body: (
    <>
      <BodyParagraph>
        When you create an access token, a refresh token will also be created at
        the same time. Use of this API allows a customer to stay logged in
        without having to provide their key and secret again over a HTTP
        request. (ie. allows the session to be refreshed without having to login
        again).
      </BodyParagraph>
      <BodyParagraph>
        This API should be used by customers when their access token has expired
        or is about to expire . This normally returns a refresh token that is
        valid for 7 days in the future from the point of token creation. Any new
        token retrieved should be then used overwriting any original token
        obtained; this assists in allowing continuous access for your
        application/s.
      </BodyParagraph>
    </>
  ),
};
export const REMOVE_ACCESS_TOKEN = {
  summary: "Remove Access Token",
  body: (
    <>
      <BodyParagraph>
        If you need to remove an access token this API can be used to revoke
        access for an active session/token that has been created via the access
        token API, This can be seen as a logout functionality/feature.
      </BodyParagraph>
      <BodyParagraph>
        This can also be used if you wish to logout and use another set of keys
        and secrets on the same machine. This assists with preventing misuse and
        keeping tighter control over your applications.
      </BodyParagraph>
    </>
  ),
};

export const ERROR_HANDLING = {
  summary: "Error Handling",
  body: (
    <>
      <BodyParagraph>Examples of common Authentication Errors</BodyParagraph>
      <BodyParagraph>
        <b>Bad Request (400)</b>: These errors can occur for the following
        reasons:
      </BodyParagraph>
      <BodyParagraph>- Missing required headers for request</BodyParagraph>
      <BodyParagraph>
        For our Access Token API the following is required:
        <br />
        <code>
          -H Authorization: Basic {"{"}base64Encoded(key:secret){"}"}
        </code>
      </BodyParagraph>
      <BodyParagraph>
        For our Refresh Access Token API the following headers are required:
        <br />
        <code>
          {" "}
          -H Authorization: Bearer {"{"}JWT Refresh Token{"}"} <br />
          -H Client-Id: {"{"}your-api-key{"}"}
        </code>
      </BodyParagraph>
      <BodyParagraph>
        For our Remove Access Token API the following headers are required:
        <br />
        <code>
          {" "}
          -H Authorization: Bearer {"{"}JWT Access Token{"}"} <br />
          -H Client-Id: {"{"}your-api-key{"}"}
        </code>
      </BodyParagraph>
      <BodyParagraph>
        When accessing another service e.g shipping every request requires the
        following headers:
        <br />
        <code>
          {" "}
          -H Authorization: Bearer {"{"}JWT Access Token{"}"} <br />
          -H Client-Id: {"{"}your-api-key{"}"}
        </code>
      </BodyParagraph>
      <BodyParagraph>
        <b>Unauthorized (401)</b>: This error can occur for the following
        reasons , If this is received then we suggest you check the below:
      </BodyParagraph>
      <BodyParagraph>
        - Access/Refresh Token is expired . Recommend to attempt to generate new
        token/s.
      </BodyParagraph>
      <BodyParagraph>
        - Invalid/Inactive key and secret pair provided . Validate or regenerate
        the Key & Secret pair via the{" "}
        <Link to={`${PATHS.REGISTRATION}/#${PATHS.CUSTOMER_PORTAL}`}>
          customer portal
        </Link>
      </BodyParagraph>
      <BodyParagraph></BodyParagraph>
      <BodyParagraph>
        <b>Forbidden (403)</b>: These errors can occur for the following
        reasons:
      </BodyParagraph>
      <BodyParagraph>
        - IP restrictions are enabled on the account, If this is received we
        would recommend referring to the Customer Integration team.
      </BodyParagraph>
      <BodyParagraph>
        <b>Too Many Requests (429)</b>: These errors can occur for the following
        reasons:
      </BodyParagraph>
      <BodyParagraph>
        - If you are accessing our API’s via the sandbox environment, rate
        limits are in place to prevent abuse and allow for fair usage. Please
        note that these limits are more restrictive than those in place within
        our live environment.
      </BodyParagraph>
      <BodyParagraph>
        - Check that authentication actions are not occurring too frequently,
        due to the expiry time on the access and refresh token pair calls to
        authentication methods should be limited and low frequency and only
        authenticating when necessary.
      </BodyParagraph>
    </>
  ),
};
