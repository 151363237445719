import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import AuthModal from "../components/Authentication/AuthModal";
import Breadcrumb from "../components/Navigation/Breadcrumb";
import ErrorTryAgain from "../components/Typography/ErrorTryAgain";
import { getAuthHeader } from "../helpers/security";
import { getPageTitle, joinWithHyphen } from "../helpers/strings";
import {
  swaggerAvailableAuth,
  swaggerTransformer,
} from "../helpers/swagger.transformer";
import useModal from "../hooks/useModal";
import SelectedDoc from "../pages/SelectedDoc";

const RenderSelectedDoc = ({
  swaggerData,
  isSmallScreen,
  toggle,
  baseUrl,
  previousStep,
  nextStep,
  basicAuth,
  authToken,
  authTokenRefresh,
}) => {
  // show loading logo when no data
  if (!swaggerData) {
    return null; // HACK return null here because JSON loads so fast this causes a flicker effect which doesnt look good <Spinner />;
  }

  return (
    <SelectedDoc
      swaggerData={swaggerData}
      isSmallScreen={isSmallScreen}
      toggle={toggle}
      baseUrl={baseUrl}
      previousStep={previousStep}
      nextStep={nextStep}
      basicAuth={basicAuth}
      authToken={authToken}
      authTokenRefresh={authTokenRefresh}
    />
  );
};
const DocsLayout = ({ apis, allMethods, method, swaggerPath }) => {
  const { isShowing, toggle } = useModal();
  const [authToken, setAuthToken] = useState("");
  const [basicAuth, setBasicAuth] = useState("");
  const [authTokenRefresh, setAuthTokenRefresh] = useState("");
  const { pathname } = useLocation();
  const [availableAuth, setAvailableAuth] = useState("");
  const [swaggerData, setSwaggerData] = useState("");
  const [transformedSwaggerData, setTransformedSwaggerData] = useState({});
  const [swaggerFailedToLoad, setSwaggerFailedToLoad] = useState(false);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });
  const pathParts = pathname.split("/");
  const baseUrl = pathParts.slice(0, pathParts.length - 1).join("/");
  const currentSummary = joinWithHyphen(pathParts[pathParts.length - 1]);

  const currentApiMethods = allMethods
    .filter((method) => pathParts.includes(method.docName))
    .sort((a, b) =>
      a.method === "post" && b.method === "get"
        ? -1
        : a.method === "get" && b.method === "post"
          ? 1
          : 0
    );

  const currentIndex = currentApiMethods.findIndex(
    (step) =>
      joinWithHyphen(step.summary.toLowerCase()) ===
      currentSummary.toLowerCase()
  );
  const previousStep =
    currentIndex > 0 ? currentApiMethods[currentIndex - 1].summary : null;
  const nextStep =
    currentIndex < currentApiMethods.length - 1
      ? currentApiMethods[currentIndex + 1].summary
      : null;

  useEffect(() => {
    if (swaggerPath) {
      const isTransformedSwaggerDataNotInCache =
        !transformedSwaggerData?.[swaggerPath];

      if (isTransformedSwaggerDataNotInCache) {
        //load and cache the transformed data
        fetch(`/docs/${swaggerPath}`)
          .then((response) => response.json())
          .then((swaggerDoc) => {
            const availableAuth = swaggerAvailableAuth(swaggerDoc);
            let transformedData = swaggerTransformer(swaggerDoc);

            setAvailableAuth(availableAuth);
            setTransformedSwaggerData((prevTransformedSwaggerData) => ({
              ...prevTransformedSwaggerData,
              [swaggerPath]: {
                transformedData,
                availableAuth,
              },
            }));
          })
          .catch((e) => {
            setSwaggerFailedToLoad(true);
          });
      } else if (
        transformedSwaggerData?.[swaggerPath]?.transformedData &&
        method
      ) {
        const selectedDoc = transformedSwaggerData[
          swaggerPath
        ].transformedData.filter(
          ({ summary, method: transformedMethod }) =>
            summary === method.summary && transformedMethod === method.method
        )[0]; //select the selected api only not the full list

        //set the appropriate auth for the new selected doc
        if (transformedSwaggerData?.[swaggerPath]?.availableAuth) {
          setAvailableAuth(
            transformedSwaggerData[swaggerPath].availableAuth.filter(
              (auth) => auth.name === selectedDoc.authMethod
            )
          );
        }
        // select the already pretransformed data, this speeds up switching between pages in a doc
        setSwaggerData(selectedDoc);
      }
    }
  }, [swaggerPath, allMethods, method, transformedSwaggerData]);

  const checkAuthed = (swaggerData) => {
    if (!swaggerData?.authMethod) {
      return false;
    }

    return !!getAuthHeader(
      swaggerData.authMethod,
      basicAuth,
      authToken,
      authTokenRefresh
    );
  };

  return (
    <>
      <div className="flex justify-between relative 2xl:justify-center flex-grow">
        <div className="w-full max-w-full overflow-auto">
          {swaggerFailedToLoad && <ErrorTryAgain />}
          {!swaggerFailedToLoad && (
            <div className="px-[24px]">
              {swaggerData && (
                <Breadcrumb
                  paths={pathname}
                  showModal={toggle}
                  isAuthed={checkAuthed(swaggerData)}
                  swaggerPath={swaggerPath}
                  combineVersions={true}
                />
              )}
              <div className="flex justify-end"></div>
              <RenderSelectedDoc
                swaggerData={swaggerData}
                isSmallScreen={isSmallScreen}
                toggle={toggle}
                baseUrl={baseUrl}
                previousStep={previousStep}
                nextStep={nextStep}
                basicAuth={basicAuth}
                authToken={authToken}
                authTokenRefresh={authTokenRefresh}
              />
            </div>
          )}
        </div>
      </div>
      <AuthModal
        isShowing={isShowing}
        hide={toggle}
        setAuthToken={setAuthToken}
        setBasicAuth={setBasicAuth}
        setAuthTokenRefresh={setAuthTokenRefresh}
        isAuthed={authToken}
        isBasicAuthed={basicAuth}
        isRefreshAuthed={authTokenRefresh}
        page={getPageTitle(pathname)}
        availableAuth={availableAuth}
      />
    </>
  );
};

export default DocsLayout;
