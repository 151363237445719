import { joinWithHyphen } from "../../helpers/strings";

export default function GettingStartedHero() {
  return (
    <div className="pb-[40px]">
      <section
        id={joinWithHyphen("Introduction")}
        className="flex flex-col pb-[16]"
      >
        <img
          src={"/images/gettingStarted/getting-started.png"}
          alt="Vector graphic of man holding box"
          className="w-[50%] self-center"
        ></img>
        <h1 className="text-center text-[32px] tracking-heading">
          DPD API Portal
        </h1>
        <div className="dpd-border-bottom 1p mt-[8px] mb-[18px] solid" />
        <p className="text-center text-[16px] leading-6	">
          DPD UK REST based API's provide an interface to allow you to automate
          tasks such as
          <br />
          create shipments, produce shipping labels , generate returns and track
          your parcels within
          <br />
          your own application/s.
        </p>
      </section>
    </div>
  );
}
