import { SECTIONS as AUTH_SECTIONS } from "./auth/";
import { SECTIONS as COLLECTIONS_SECTIONS } from "./collections/";
import { SECTIONS as SHIPPING_SECTIONS } from "./shipping/";
import { PAGES } from "../";

const steps = {
  [PAGES.AUTH]: {
    sections: AUTH_SECTIONS,
  },
  [PAGES.SHIPPING]: {
    sections: SHIPPING_SECTIONS,
  },
  [PAGES.COLLECTIONS]: {
    sections: COLLECTIONS_SECTIONS,
  },
};

export default steps;
